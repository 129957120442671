import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/welcome/css/normal.css'
import i18n from './language/i18n.js'
import router from './router/router'

//引入iconfont
import '@/welcome/iconfont/iconfont.css'


Vue.use(ElementUI)


new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
