import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  en: {
    changeLang: "language",
    account: "account",
    confirm: "confirm",
    cancel: "cancel",
    enterAccount: "Please enter the NDAC account",
    protocols: "Please review all protocols",
    login: "ndac login",
    partnersLogin: "partners",
    language: "English",
    selectLogin: "Sign in",
    clause: "I have read and agreed the above terms",
    email: "Email",
    password: "Password",
    agreement: "End-Customer License Agreement for the Nokia Software (“ECLA”)",
    parentAgreement: "partner-Customer License Agreement for the Nokia Software (“PCLA”)",
    agree: "confirm",
    signIn: "Sign in",
    agent: "I am an Agent",
    business: "For business",
    consumers: "For consumers",
    innovation: "Innovation",
    aboutUs: "About us",
    nokiaOZO: "Nokia OZO",
    licensingOpportunities: "Licensing opportunities",
    partners: "Partners and ecosystems",
    phones: "Phones",
    laptops: "Laptops",
    nokiaBell: "Nokia Bell Labs",
    innovationPlatform: "Innovation platform",
    innovationEcosystem: "pen innovation ecosystem",
    capital: "NGP Capital",
    company: "Company",
    newsroom: "Newsroom",
    investors: "Investors",
    sustainability: "Sustainability",
    careers: "Careers",
    compliance: "Compliance",
    contactSupport: "Contact and support",
    support: "Support",
    extranet: "Extranet access",
    developer: "Developer resources and APIs",
    contactUs: "Contact us",
    rule: "By using Nokia DAC you agree to our",
    elac: "ECLA",
    ecsa: "ECSA",
    addendum: "Data Protection Addendum",
    order: "Purchase Order",

    //合作伙伴区域
    
    partnerElac: "PCLA",
    partnerEcsa: "PCSA",
    partnerAddendum: "Data Protection Addendum",
    partnerAuthentication: "partners Account authentication",
  },
  zh: {
    changeLang: "语言",
    account: "账号",
    confirm: "确认",
    cancel: "取消",
    enterAccount: "请输入NDAC官方指定账号",
    protocols: "请查看所有协议",
    login: "ndac 登录",
    partnersLogin: "合作伙伴",
    language: "简体中文",
    selectLogin: "登录选项",
    clause: "我已认真阅读并同意以上条款",
    email: "邮箱",
    password: "密码",
    agreement: "诺基亚软件终端用户许可协议（“终端用户许可协议”）",
    parentAgreement: "诺基亚软件伙伴用户许可协议（“伙伴用户许可协议”）",
    agree: "同意",
    signIn: "注册",
    agent: "代理人入口",
    business: "企业客户",
    consumers: "消费者客户",
    innovation: "创新",
    aboutUs: "关于我们",
    nokiaOZO: "诺基亚 OZO",
    licensingOpportunities: "品牌授权",
    partners: "合作伙伴和生态系统",
    phones: "电话",
    laptops: "笔记本电脑",
    nokiaBell: "诺基亚贝尔实验室",
    innovationPlatform: "创新平台",
    innovationEcosystem: "开放创新生态系统",
    capital: "NGP 投资", 
    company: "公司",
    newsroom: "新闻中心",
    investors: "投资者",
    sustainability: "可持续性",
    careers: "招聘",
    compliance: "法律法规",
    contactSupport: "联系方式和支持",
    support: "支持",
    extranet: "外网访问",
    developer: "开发者资源和 APIs",
    contactUs: "联系我们",
    rule: "使用本系统代表您已同意以下条款",
    elac: "终端用户许可协议",
    ecsa: "终端用户服务协议",
    addendum: "数据保护附录",
    order: "采购订单",

    partnerElac: "伙伴用户许可协议",
    partnerEcsa: "伙伴用户服务协议",
    partnerAddendum: "数据保护附录",

    //合作伙伴区域
    partnerAuthentication: "合作伙伴账号认证",
  },
};

let browserLang = navigator.language
  ? navigator.language
  : navigator.browserLanguage;

browserLang = browserLang.substr(0, 2);

let language = localStorage.getItem("lang") || browserLang || "zh";

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", language);
}

const i18n = new VueI18n({
  locale: language,
  messages: messages,
});

export default i18n;
