import Vue from 'vue'
import Router from 'vue-router'

const home = () => import('@/views/home.vue')
const help = () => import('@/views/help.vue')
// const login = () => import('@/views/login.vue')


Vue.use(Router)

const routes = [
    //欢迎页打包

    {
        path: '/',
        name: 'home',
        component: home,
    },
    // {
    //     path: '/local/user/login',
    //     name: 'login',
    //     component: login,
    // },
    {
        path: '/login',
        name: 'help',
        component: help,
    },

    //登录页打包

    // {
    //     path:"/",
    //     redirect:'/local/user/login'
    // },
    // {
    //     path: '/local/user/login',
    //     name: 'login',
    //     component: login,
    // },
    // {
    //     path: '/help',
    //     name: 'help',
    //     component: help,
    // },

]

export default new Router({
    mode: 'history',
    routes: routes,
})
